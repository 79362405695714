import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { Page, PageContent, UserAgreementLink } from '../../../components/UI';
import { EventCheckoutHeader } from '../../../components/event';
import {
  EmailForm,
  IEmailForm,
  PaymentForm,
  IPaymentForm,
  CheckoutForm,
  FormInfo,
  FormError,
} from '../../../components/forms';
import { Utils, AnalyticService } from '../../../services';
import { checkoutProcess, checkoutSetEmail, checkoutInit, checkCurrentCheckout } from '../../../store/checkout/actions';
import { CheckoutHelper, EventHelper } from '../../../helpers';
import { ICheckoutStoreState } from '../../../store/checkout/types';
import { IBookStoreState } from '../../../store/book/types';
import { IErrorStoreState } from '../../../store/app/types';
import { IUserStoreState } from '../../../store/user/types';
import { PaymentProcessResult } from '../../../store/payment/types';
import { IGlobalStoreState } from '../../../store';
import { IDistributionStoreState } from '../../../store/distribution/types';
import { BookStatus } from '../../../store/enums';
import { Translation, withTranslation } from 'react-i18next';
import RouteService from '../../../services/routeService';
import { StripeCheckoutForm } from '../../../components/forms/stripeCheckoutForm';
import { motion } from 'framer-motion';

interface ICheckoutPageProps {
  eventSlug: string;
  checkout: ICheckoutStoreState;
  isInitializing: boolean;
  sectorSlug: string;
  rowSlug: string;
  tickets: number;
  book: IBookStoreState;
  error: IErrorStoreState;
  user: IUserStoreState;
  isAuthorized: boolean;
  distribution: IDistributionStoreState;
  token: string;

  go: (url: string) => void;
  setEmail: (email: string) => void;
  checkoutProcess: (usePoints?: boolean) => Promise<PaymentProcessResult>;
  checkoutInit: (usePoints?: boolean) => Promise<void>;
  checkCheckout: () => Promise<void>;
  t: (key: string) => string;
}

interface ICheckoutPageState {
  errors?: any;
  redirectUrl?: string;
}

class CheckoutPage extends React.PureComponent<ICheckoutPageProps, ICheckoutPageState> {
  private routeSrv = new RouteService();
  private checkoutSteps: number = 2;
  private analyticSrv = new AnalyticService();

  constructor(props: ICheckoutPageProps) {
    super(props);

    this.state = {
      errors: null,
      redirectUrl: null,
    };
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps: ICheckoutPageProps) {
    const isErrorChanged = !Utils.isEqual(this.props.error, nextProps.error);
    const isEmailChanged = this.props.checkout.email !== nextProps.checkout.email;
    const IsInProcess =
      CheckoutHelper.getIsProcessed(this.props.checkout) && CheckoutHelper.getIsInProcess(nextProps.checkout);
    const isAvailableForProcessing =
      isEmailChanged && CheckoutHelper.getIsAvailableForProcessing(nextProps.checkout) && !nextProps.isAuthorized;
    if (IsInProcess) this.init(nextProps);
    if (isErrorChanged) this.setState({ errors: { form: nextProps.error } });
    if (CheckoutHelper.isRedirectingMethod(nextProps.checkout)) {
      this.setState({ redirectUrl: nextProps.checkout.process.paymentRedirectUrl });
    } else if (isAvailableForProcessing) {
      this.handlePaymentFormSubmit(nextProps.checkout.usePoints);
    }
  }

  componentWillUpdate(nextProps: ICheckoutPageProps) {
    this.checkoutSteps = !!nextProps.user && !!nextProps.user.email ? 3 : 2;
  }

  render() {
    const checkoutModel = this.props.checkout;
    const isInitiated = CheckoutHelper.getIsInitiated(checkoutModel);
    if (!isInitiated || this.props.isInitializing || !checkoutModel) return null;

    const isEmailSet = CheckoutHelper.getIsEmailSet(checkoutModel);
    const isProcessed = CheckoutHelper.getIsProcessed(checkoutModel);
    const event = checkoutModel.init.event;

    return (
      <Translation>
        {(t) => (
          <Page
            title={`${t('Checkout.TicketPurchase')} | ${event.title} - ${event.subtitle}`}
            description={EventHelper.getMetaDescription(event)}
            keywords={[event.title, event.venue.title, t('Checkout.BuyTicket'), t('Checkout.Concert')]}
            imageUrl={event.posterUrl}
          >
            <EventCheckoutHeader checkoutModel={checkoutModel} isAuthorized={this.props.isAuthorized} />
            {isInitiated && !isEmailSet && this.renderEmailForm(checkoutModel)}
            {isEmailSet && !isProcessed && this.renderUsePointsSelector(checkoutModel)}
            {isProcessed && this.renderPaymentForm(checkoutModel)}
            {this.renderFooter()}
          </Page>
        )}
      </Translation>
    );
  }

  private renderFooter() {
    return (
      <Footer>
        <div>{this.renderError()}</div>
      </Footer>
    );
  }

  private renderEmailForm(checkoutModel: ICheckoutStoreState) {
    const distributionInfo = CheckoutHelper.getDistributionInfo(checkoutModel, this.props.distribution);
    const email = this.props.user ? this.props.user.email : checkoutModel.email;
    const title = this.props.isAuthorized
      ? this.props.t('Checkout.BookTicketsNoteAuthorized')
      : this.props.t('Checkout.BookTicketsNote');
    return (
      <Translation>
        {(t) => (
          <PageContent roundCorners>
            <Header>
              {t('Checkout.Step')} 1 {t('Checkout.Of')} {this.checkoutSteps}
            </Header>
            <Title>{title}</Title>
            <EmailForm
              errors={this.state.errors}
              email={email}
              emailPlaceholder={t('Checkout.EnterEmail')}
              emailConfirmPlaceholder={t('Checkout.RepeatEmail')}
              warning={t(distributionInfo, { distributor: this.props.distribution.distributorSlug })}
              submitTitle={t('Checkout.Next')}
              onSubmit={(formModel: IEmailForm) => this.handleEmailFormSubmit(formModel)}
            />
            <MenuBlock footer>
              <List>
                <ListItem>
                  <UserAgreementLink>
                    <div>By paying, you agree to the terms and conditions</div>
                  </UserAgreementLink>
                </ListItem>
              </List>
            </MenuBlock>
          </PageContent>
        )}
      </Translation>
    );
  }

  private renderUsePointsSelector(checkoutModel: ICheckoutStoreState) {
    const isRedirecting = !!this.state.redirectUrl;
    if (isRedirecting) window.location.href = this.state.redirectUrl;
    return (
      <Translation>
        {(t) => (
          <PageContent roundCorners>
            <Header>
              {t('Checkout.Step')} 2 {t('Checkout.Of')} {this.checkoutSteps}
            </Header>
            <Title>{t<string>('Checkout.PayByCard')}</Title>
            {!isRedirecting && this.props.isAuthorized && (
              <PaymentForm
                checkoutModel={checkoutModel}
                user={this.props.user}
                errors={this.state.errors}
                onUsePointsChange={(usePoints: boolean) => this.handleUsePointsChange(usePoints)}
                onSubmit={(formModel: IPaymentForm) => {
                  if (formModel) this.handlePaymentFormSubmit(formModel.usePoints);
                }}
              />
            )}
          </PageContent>
        )}
      </Translation>
    );
  }

  private renderPaymentForm(checkoutModel: ICheckoutStoreState) {
    const step = this.props.isAuthorized && !!this.props.user && !!this.props.user.email ? 3 : 2;
    return (
      <Translation>
        {(t) => (
          <PageContent roundCorners>
            <Header>
              {t('Checkout.Step')} {step} {t('Checkout.Of')} {this.checkoutSteps}
            </Header>
            <Title>{t<string>('Checkout.PayByCard')}</Title>
            {this.renderCheckoutContent(checkoutModel)}
          </PageContent>
        )}
      </Translation>
    );
  }

  private renderCheckoutContent(checkoutModel: ICheckoutStoreState) {
    const isRedirecting = !!this.state.redirectUrl;
    const needForm = CheckoutHelper.getIsNeedPaymentForm(checkoutModel);
    if (isRedirecting) window.location.href = this.state.redirectUrl;
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            {!isRedirecting && needForm && <StripeCheckoutForm
                paymentToken={checkoutModel.process.paymentToken}
                provider={checkoutModel.process.stripeProvider}/>}
            {isRedirecting && (
              <RedirectWrapper>
                <RedirectTitle>{t('Checkout.PaymentRedirectionMessage')}</RedirectTitle>
              </RedirectWrapper>
            )}
          </React.Fragment>
        )}
      </Translation>
    );
  }

  private renderError() {
    const stateErrors = this.state.errors;
    const errorMessage = stateErrors?.form?.message;
    return !!stateErrors && <ErrorWrapper key="form-error">{errorMessage}</ErrorWrapper>;
  }

  private handleEmailFormSubmit(formModel: IEmailForm) {
    if (formModel) {
      this.props.setEmail(formModel.email);
      this.analyticSrv.trackEvent('InitiateCheckout');
    }
  }

  private handlePaymentFormSubmit(usePoints: boolean) {
    this.props.checkoutProcess(usePoints);
    this.analyticSrv.trackEvent('PurchaseProcess');
  }

  private handleUsePointsChange(usePoints: boolean) {
    this.props.checkoutInit(usePoints);
  }

  private init(props: ICheckoutPageProps) {
    const book = props.book;
    const checkout = props.checkout;
    if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
      props.checkCheckout();
      return;
    }
    if (!book || !book.event) {
      props.go(this.routeSrv.getBookRoute(props.eventSlug, null, props.sectorSlug, props.rowSlug, props.tickets));
      return;
    } else if (book.event.notAvailableForPurchase) {
      props.go(this.routeSrv.getEventRoute(book.event.slug));
      return;
    } else {
      //ToDo: interfered with the correct operation of the route to the schemes
      // props.go(this.routeSrv.getCheckoutRouteFromBook(book));
    }
    if (!CheckoutHelper.getIsInitiated(checkout)) {
      props.go(this.routeSrv.getBookRouteFromBook(book));
      return;
    } else if (props.checkout.isRedoing) {
      return;
    } else if (CheckoutHelper.getIsAvailableForProcessing(checkout) && !this.props.isAuthorized) {
      this.handlePaymentFormSubmit(checkout.usePoints);
    } else if (CheckoutHelper.getIsInProcess(checkout)) {
      this.props.go(this.routeSrv.getBookResultRoute(BookStatus.InProcess));
      return;
    } else if (CheckoutHelper.getIsCompleted(checkout)) {
      this.props.go(this.routeSrv.getBookResultRoute(BookStatus.Success));
      return;
    }
  }
}

const Header: any = styled.h2`
  font-size: 14px;
  margin: 0 0 10px 0;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

const RedirectWrapper = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const RedirectTitle = styled(Header as any)``;

const Title = styled.h1`
  font-size: 14px;
  text-align: center;
`;

const Warning = styled(FormInfo as any)`
  padding: 10px 0 0 0;
`;

const ErrorWrapper = styled(FormError as any)`
  text-align: center;
  width: 100%;
`;

const Footer = styled.footer``;

const mapStateToProps = (state: IGlobalStoreState, ownProps: any) => {
  return {
    eventSlug: ownProps.match && ownProps.match.params && ownProps.match.params.eventSlug,
    sectorSlug: ownProps.match && ownProps.match.params && ownProps.match.params.sectorSlug,
    rowSlug: ownProps.match && ownProps.match.params && ownProps.match.params.rowSlug,
    tickets: ownProps.match && ownProps.match.params && +ownProps.match.params.tickets,
    checkout: state.checkout,
    isInitializing: state.app.isInitializing,
    book: state.book,
    error: state.app.error,
    user: state.user,
    isAuthorized: state.app.isAuthorized,
    distribution: state.distribution,
    token: ownProps.match && ownProps.match.params && ownProps.match.params.token,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  go: ownProps.history.push,
  setEmail: (email: string) => dispatch(checkoutSetEmail(email)),
  checkoutProcess: (usePoints?: boolean) => dispatch(checkoutProcess(usePoints)),
  checkoutInit: (usePoints?: boolean) => dispatch(checkoutInit(usePoints, false)),
  checkCheckout: () => dispatch(checkCurrentCheckout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckoutPage)));

const AMenuBlock = styled(motion.section as any)``;

const MenuBlock = styled(AMenuBlock as any)`
  color: ${(props) => props.theme.colors.text};
  font-size: 14px;
  margin-bottom: ${(props: any) => !props.footer && '25px'};
  margin-top: 10px;
  flex: ${(props: any) => props.grow && 1};
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
