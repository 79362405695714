export enum Pages {
  Main = '',
  EventDetails = 'event',
  Book = 'book',
  Checkout = 'checkout',
  BookResult = 'result',
  User = 'user',
  Login = 'login',
  Registration = 'registration',
  EmailConfirm = 'email-confirmation',
  Profile = 'profile',
  EditProfile = 'edit-profile',
  UserEvents = 'my-events',
  UserTickets = 'my-tickets',
  Sell = 'sell',
  PasswordRecovery = 'password-recovery',
  ResetPassword = 'password-reset',
  Contacts = 'contacts',
  PaymentsInfo = 'payments-info',
  Share = 'share',
  Widget = 'widget',
  Categories = 'categories',
  Referral = 'referral',
  Success = 'success',
  Failed = 'failed',
}

export enum BookStatus {
  Success = 'success',
  Fail = 'fail',
  Decline = 'decline',
  Cancel = 'cancel',
  Timeout = 'timeout',
  InProcess = 'processing',
}

export enum EmailConfirmStatus {
  Success = 'success',
  Failed = 'failed',
}

export enum AlertType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  None = 'none',
}

export enum PaymentProvider {
  YandexKassa = 'YandexKassa',
  BePaid = 'BePaid',
  Fanaticka = 'Fanaticka',
  Stripe = 'Stripe',
}

export enum PaymentMethod {
  BankCard = 'BankCard',
  StripeBankCard = 'StripeBankCard',
  Points = 'Points',
}

export enum StripePaymentStatus {
  canceled = 'canceled',
  requires_action = 'requires_action',
  requires_confirmation = 'requires_confirmation',
  succeeded = 'succeeded',
  processing = 'processing',
  requires_payment_method = 'requires_payment_method',
}

export enum KeySuccess {
  EmailSent = 'email-sent',
  EmailConfirmed = 'email-confirmed',
  PaymentSucceeded = 'payment-succeeded',
  PaymentInProcess = 'payment-in-process',
}

export enum KeyErrors {
  EventNotFound = 'event-not-found',
  QuotaNotFound = 'quota-not-found',
  NotEnoughTickets = 'not-enough-tickets',
  NoTickets = 'no-tickets',
  ServerError = 'server-error',
  EmailConfirmFailed = 'email-confirm-failed',
  ResetPasswordFailed = 'reset-password-failed',
  PaymentFailed = 'payment-failed',
  PaymentTimeout = 'payment-timeout',
  TicketNotFound = 'ticket-not-found',
}

export enum IssueStatus {
  Issued = 'Issued',
  Unissued = 'Unissued',
}

export enum SellStatus {
  Sold = 'Sold',
  Sale = 'Sale',
  InitiatedSale = 'InitiatedSale',
  OfferedForPurchase = 'OfferedForPurchase',
  Bought = 'Bought',
  PaymentInProcess = 'PaymentInProcess',
  PaymentDeclined = 'PaymentDeclined',
}

export enum DomainType {
  None = 0,
  By = 1,
  Ru = 2,
  Pl = 4,
  Co = 8,
  Info = 16,
  Live = 32,
  Me = 64,
  Eu = 128,
  Com = 256,
}

export enum Locale {
  en = 'en',
  ru = 'ru',
}

export enum RequestType {
  none = 0,
  confirmPhone = 1,
  auth = 2,
  token = 3,
  signup = 4,
  userTickets,
}
