import React, { Suspense, useCallback, useEffect, useLayoutEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';

import { useHistory } from 'react-router';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { IntercomProvider } from 'react-use-intercom';
import { IGlobalStoreState } from '../store';
import {hideSpinner, initApp, redirectApp} from '../store/app/actions';
import { Routes } from '../store/routes';

import Spinner from './spinner';
import Menu from './menu';
import { EventsPage } from './pages/events/eventsPage';
import EventDetailsPage from './pages/events/eventDetailsPage/eventDetailsPage';
import BookPage from './pages/book/bookPage';
import CheckoutPage from './pages/checkout/checkoutPage';
import BookResultPage from './pages/book/bookResultPage';
import BookResultErrorPage from './pages/book/bookResultErrorPage';
import UserPage from './pages/user/userPage';
import UserTicketDetailsPage from './pages/user/userTicketDetailsPage';
import UserTicketSellDetailsPage from './pages/user/userTicketSellDetailsPage';
import UserTicketPurchasePage from './pages/user/userTicketPurchasePage';
import SharePage from './pages/share/sharePage';
import ShareLinkPage from './pages/share/shareLinkPage';
import WidgetBookPage from './pages/widget/widgetBookPage';
import WidgetCheckoutPage from './pages/widget/widgetCheckoutPage';
import WidgetBookResultPage from './pages/widget/widgetBookResultPage';
import { ContactsPage, PaymentsInfoPage } from './pages/static';
import { getRegion } from '../store/region/actions';
import { AnalyticService } from '../services';
import { AlertType, DomainType, KeyErrors, Pages } from '../store/enums';
import { IHeaderStoreState } from '../store/header/types';
import { initWidgetApp } from '../store/appSettings/actions';
import { DashboardPage } from './pages/events/dashboardPage';
import { EventCategoryPage } from './pages/events/eventCategoryPage';
import { initLocation } from '../store/location/actions';
import { AppTheme } from '../settings/appTheme';
import Header from './header';
import RouteService from '../services/routeService';
import { AppSettings, DomainSetting, IntercomSettings } from '../settings/appSettings';
import { ReferralPage } from './pages/referralPage/referralPage';
import { InformationRoutes } from './pages/information/informationRoutes';
import { useRoutesHelper } from '../helpers/routesHelper';
import { WidgetInformationRoutes } from './pages/information/widgetInformationRoutes';
import { AlertError } from '../components/alert/alertError';
import EventHelper from '../helpers/eventHelper';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      light: '#1C1C1C',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFBF00',
      light: '#4B4B4B',
      dark: '#ba2d65',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [AppTheme.fonts.regularFont].join(','),
  },
});

const App = () => {
  let unlistenHistory: () => void;
  const routeSrv = new RouteService();
  const analyticSrv = new AnalyticService();
  const dispatch = useDispatch();
  const history = useHistory();
  const { changeDomainTopLevel } = useRoutesHelper();

  const isMenuShow = useSelector<IGlobalStoreState>((state) => state.menu.isShow);
  const isLoading = useSelector<IGlobalStoreState>((state) => state.app.isLoading);
  const isRedirecting = useSelector<IGlobalStoreState>((state) => state.app.isRedirection);
  const header = useSelector<IGlobalStoreState, IHeaderStoreState>((state) => state.header);
  const useNewDashboard = useSelector<IGlobalStoreState>((state) => state.appSettings.useNewDashboard);
  const isFinalized = useSelector<IGlobalStoreState>((state) => state.app.isFinalized);
  const resultType = useSelector<IGlobalStoreState, string>((state) => state.resultInfo.type);
  const resultKey = useSelector<IGlobalStoreState, string>((state) => state.resultInfo.key);

  const isError = resultType === AlertType.Error;
  const isServerError = KeyErrors.ServerError === resultKey;

  const init = useCallback(() => {
    dispatch(hideSpinner());
    analyticSrv.initialize();
    dispatch(getRegion())
      // @ts-ignore
      .then((region) => {
        const isEuDomain = location.host.includes(DomainSetting.Eu) || location.host.includes(DomainSetting.Pl);
        if (!isEuDomain) {
          const domain = changeDomainTopLevel(DomainSetting.Eu, location.hostname);
          dispatch(redirectApp(domain));
        }
        if (region.isDomainRequired) {
          const newLocation = routeSrv.getLocation(region.domainName);
          if (newLocation !== location.href) {
            dispatch(redirectApp(newLocation));
            return;
          }
        }
        dispatch(initLocation(region.city));

        const isWidgetPage = routeSrv.isActivePage(Pages.Widget);

        if (isWidgetPage) {
          const token = routeSrv.getwidgetToken();
          dispatch(initWidgetApp(token));
        } else {
          dispatch(initApp('*'));
        }
        unlistenHistory = history.listen(() => {
          analyticSrv.trackPageView(window.location.pathname + window.location.search);

          window.scrollTo(0, 0);
        });
      })
      .catch(() => {
        const splashError = document.getElementById('splashErrorId');
        splashError.hidden = false;
      });
  }, []);

  useLayoutEffect(() => {
    const domain = location.hostname;
    const domainType = EventHelper.getDomainType(domain);

    if (domainType === DomainType.Ru) {
      init();
    }
  }, [init]);

  const updateViewPortHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useLayoutEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    document.addEventListener('touchstart', () => {}, false);
    window.addEventListener('resize', () => {
      updateViewPortHeight();
    });
    window.addEventListener('scroll', () => {
      updateViewPortHeight();
    });
  }, []);

  useEffect(
    () => () => {
      document.removeEventListener('touchstart', () => {});
      window.removeEventListener('resize', () => {
        updateViewPortHeight();
      });
      window.removeEventListener('scroll', () => {
        updateViewPortHeight();
      });
      unlistenHistory();
    },
    [unlistenHistory]
  );

  useEffect(() => {
    updateViewPortHeight();

    if (isMenuShow || isFinalized || isLoading || header) {
      !isFinalized || isLoading || header.showPlayer
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll');
    }
  }, [header.showPlayer, isFinalized, isLoading, isMenuShow]);

  const { getResultStatusRoute } = useRoutesHelper();

  useEffect(() => {
    if (isServerError) {
      history.replace(getResultStatusRoute(AlertType.Error, KeyErrors.ServerError));
    }
  }, [isServerError]);

  return (
    <Suspense fallback={<Spinner isLoading={isLoading} />}>
      <IntercomProvider appId={IntercomSettings.APP_ID} autoBoot>
        <ThemeProvider theme={AppTheme}>
          <MuiThemeProvider theme={theme}>
            <AppContainer noScroll={!!resultType}>
              <GlobalStyles theme={AppTheme} />
              {!isRedirecting && (
                <Container>
                  <Header />
                  <AlertError />
                  <Switch>
                    <Route exact path={Routes.Main} component={useNewDashboard ? DashboardPage : EventsPage} />
                    <Route exact path={Routes.MainSortLocation} component={DashboardPage} />
                    {!useNewDashboard && <Route exact path={Routes.NewDashboard} component={DashboardPage} />}
                    <Route exact path={Routes.RegistrationReferral} component={UserPage} />
                    <Route exact path={Routes.Category} component={EventCategoryPage} />
                    <Route exact path={Routes.Event} component={EventDetailsPage} />
                    <Route exact path={Routes.BookResultError} component={BookResultErrorPage} />
                    <Route exact path={Routes.BookResult} component={BookResultPage} />
                    <Route exact path={Routes.Checkout} component={CheckoutPage} />
                    <Route exact path={Routes.Referral} component={ReferralPage} />
                    <Route exact path={Routes.TokenReferral} component={BookPage} />
                    <Route exact path={Routes.Book} component={BookPage} />
                    <Route exact path={Routes.Share} component={SharePage} />
                    <Route exact path={Routes.ShareLink} component={ShareLinkPage} />
                    <Route exact path={Routes.UserTicketDetails} component={UserTicketDetailsPage} />
                    <Route exact path={Routes.UserTicketSellDetails} component={UserTicketSellDetailsPage} />
                    <Route exact path={Routes.UserTicketPurchase} component={UserTicketPurchasePage} />
                    <Route path={Routes.User} component={UserPage} />
                    <Route exact path={Routes.Contacts} component={ContactsPage} />
                    <Route exact path={Routes.PaymentsInfo} component={PaymentsInfoPage} />
                    <Route exact path={Routes.WidgetBookResult} component={WidgetBookResultPage} />
                    <Route exact path={Routes.WidgetBook} component={WidgetBookPage} />
                    <Route exact path={Routes.WidgetCheckout} component={WidgetCheckoutPage} />
                    <Route path={isError ? Routes.Failed : Routes.Success} component={InformationRoutes} />
                    <Route
                      path={`/${Pages.Widget}/${isError ? Pages.Failed : Pages.Success}`}
                      component={WidgetInformationRoutes}
                    />
                    <Redirect to={Routes.Main} />
                  </Switch>
                  <Spinner />
                  <Menu />
                </Container>
              )}
            </AppContainer>
          </MuiThemeProvider>
        </ThemeProvider>
      </IntercomProvider>
    </Suspense>
  );
};

type GlobalStylesType = {
  theme: {
    colors: {
      text: string;
      bg: string;
    };
    fonts: {
      regularFont: string;
    };
  };
};

const GlobalStyles = createGlobalStyle<GlobalStylesType>`
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    color: ${(props) => props.theme.colors.text};
    font-family: ${(props) => props.theme.fonts.regularFont};
    font-weight: normal;
    background-color: ${(props) => props.theme.colors.bg};

    &.no-scroll {
      overflow: hidden;
    }

    &.splash-show {
      .spinner {
        background-color: transparent;
      }
    }
  }

  * {
    box-sizing: border-box;
    font-weight: normal;
  }
`;

const AppContainer = styled.div<{ noScroll: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.bg};
  overflow-x: hidden;
  overflow-y: ${(props) => (props.noScroll ? 'hidden' : 'auto')};
  max-height: ${(props) => (props.noScroll ? '100vh' : 'unset')};
`;
const Container = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${(props) => props.theme.maxWidth};
  width: 100%;
  box-shadow: 0 0 15px 2px black;
  background: ${(props) => props.theme.colors.bg};
  position: relative;
`;

export default App;
