import * as React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import i18next from 'i18next';
import { Image, PageHeader } from '../UI';
import { IProfileTicketStoreState } from '../../store/ticket/ProfileTicketStoreState';
import EventInfoItem from './eventInfoItem';

interface IEventSellHeaderProps {
  ticket: IProfileTicketStoreState;
}

export class EventSellHeader extends React.PureComponent<IEventSellHeaderProps> {
  constructor(props: IEventSellHeaderProps) {
    super(props);
  }

  render() {
    const event = this.props.ticket.event;
    return (
      <Header>
        <Poster src={event.posterUrl} />
        <InfoWrapper>
          <Title>{event.title}</Title>
          <List>
            <ListItem>
              <EventInfoItem
                icon="calendar"
                themed
                title={dayjs(event.date).format('DD MMMM YYYY')}
                subtitle={dayjs(event.date).format('LT')}
              />
            </ListItem>
            {event.venue && (
              <ListItem>
                <EventInfoItem
                  icon="location"
                  themed
                  title={event.venue.title}
                  subtitle={`${event.venue.address}, ${event.venue.city}`}
                />
              </ListItem>
            )}
            {this.renderTicketsInfo(this.props.ticket)}
            {this.renderPriceInfo(this.props.ticket)}
          </List>
        </InfoWrapper>
      </Header>
    );
  }

  private renderTicketsInfo(ticket: IProfileTicketStoreState) {
    if (!ticket || !ticket.ticketQuota) {
      return null;
    }
    const quota = ticket.ticketQuota;
    let title = !!quota.sector ? quota.sector.title : quota.title;
    if (ticket.rowTitle) {
      title += ` ${i18next.t('SellHeader.Row')} ${ticket.rowTitle}`;
    }
    if (ticket.seatTitle) {
      title += ` ${i18next.t('SellHeader.Seat')} ${ticket.seatTitle}`;
    }
    return (
      <ListItem>
        <EventInfoItem key={ticket.ticketId} icon="person" themed title={title} />
      </ListItem>
    );
  }

  private renderPriceInfo(ticket: IProfileTicketStoreState) {
    if (!ticket || !ticket.ticketQuota) {
      return null;
    }

    const commission = ticket.priceComission;
    const comissionDescription = ticket.comissionDescription;
    const percentageComission = ticket.percentageComission;
    const currency = ticket.currency;
    let subtitle =
      commission > 0
        ? `${commission} ${currency} (${comissionDescription} ${percentageComission * 100}% ${i18next.t(
            'SellHeader.PaidByBuyer'
          )})`
        : '';//i18next.t('SellHeader.NoCommission');

    let title = `${ticket.price} ${currency}`;
    return (
      <ListItem>
        <EventInfoItem icon="purse" themed title={title} subtitle={subtitle} />
      </ListItem>
    );
  }
}

const InfoWrapper = styled.div`
  z-index: 1;
  width: 100%;
  margin-bottom: 4vh;
`;

const Title = styled.h1`
  font-size: 36px;
  margin: 0 0 20px 0;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.bolderFont};
`;

const Poster = styled(Image as any)`
  position: absolute;
  object-position: 50% 20%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 50px;
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Header = styled(PageHeader as any)`
  max-height: 60vh;
`;

export default EventSellHeader;
