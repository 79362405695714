import React, { FC } from 'react';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './checkoutForm';
import { StripeSettings } from '../../settings/appSettings';

const stripeKeys: { [key: number]: string } = {
  0: StripeSettings.STRIPE_KEY,
  1: StripeSettings.STRIPE_KEY,
  2: StripeSettings.STRIPE_TM_KEY,
};

const getStripePromise = (provider: number) => {
  const stripeKey = stripeKeys[provider];
  console.log('stripeKey', stripeKey);

  if (!stripeKey) {
    throw new Error(`No Stripe key found for provider ${provider}`);
  }
  return loadStripe(stripeKey);
};

export const StripeCheckoutForm: FC<ContentPropsType> = ({ paymentToken, provider }) => {
  console.log('provider', provider);
  const stripePromise = getStripePromise(provider);

  const options: StripeElementsOptions = {
    locale: 'en-GB',
    clientSecret: paymentToken,
    loader: 'always',
    appearance: {
      ...{ theme: 'night', variables: { borderRadius: '24px' } },
    },
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

type ContentPropsType = {
  paymentToken: string;
  provider: number;
};
